import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { getBasketStock, getStock } from '../redux/actions/products'
import { arrayUnique, findCurrentPlan, getSkus } from '../utils/utils'
import {
  getUser,
  getUserOrders,
  deleteUser,
  getUserBalance,
} from '../redux/actions/user'
import { getPromotions, setFuturePrice } from '../redux/actions/promotions'
import { getParameters } from '../redux/actions/parameters'
import { getCountries } from '../redux/actions/countries'
import {
  getInProgressContractCSUB,
  getInProgressContractSUB,
} from '../redux/actions/contract'
import {
  isSubscription,
  SAVED_END_DATE,
  SAVED_START_DATE,
} from '../utils/constants'
import { useRouter } from 'next/router'
import { getAssociatedProducts } from '../managers/product/packManager'
import { useCart } from '../hooks/useCart'
import { RootState } from '../redux/reducers/rootReducer'
import { GetMapDispatchTypes } from '../interfaces/common/redux'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

type Tprops = ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps> & {
    children?: any
  }

const mapStateToProps = (state: RootState) => ({
  cart: state.cart,
  products: state.products,
  product: state.product,
  parameters: state.parameters,
  packs: state.packs,
  user: state.user,
  promotions: state.promotions,
  stores: state.stores,
  contract: state.contract,
})

const mapDispatchToProps = {
  getBasketStock: getBasketStock,
  getParameters: getParameters,
  getStock: getStock,
  getUserOrders: getUserOrders,
  setFuturePrice: setFuturePrice,
  getUser: getUser,
  deleteUser: deleteUser,
  getPromotions: getPromotions,
  getCountries: getCountries,
  getInProgressContractCSUB: getInProgressContractCSUB,
  getInProgressContractSUB: getInProgressContractSUB,
  getUserBalance: getUserBalance,
}

const CartManager = ({
  children,
  packs,
  getBasketStock,
  getUserOrders,
  getUser,
  getPromotions,
  deleteUser,
  setFuturePrice,
  getParameters,
  getInProgressContractCSUB,
  getInProgressContractSUB,
  stores,
  parameters,
  getCountries,
  promotions,
  getStock,
  user,
  product,
  products,
  cart,
  getUserBalance,
}: Tprops) => {
  const {
    deleteCart,
    createCart,
    createSubscriptionCart,
    getCart,
    updateCartSubscription,
  } = useCart()
  // Hook used to check if component is already mounted, to prevent it to call each useEffect hooks on all rendering
  const didMountProducts = useRef(false)
  const didMountProduct = useRef(false)
  const didMountPacks = useRef(false)
  const router = useRouter()
  const warehouseId = stores?.selectedStore?.warehouseId
    ? stores?.selectedStore?.warehouseId
    : process.env.CUSTOM_WAREHOUSE
    ? process.env.CUSTOM_WAREHOUSE
    : null
  const isEnableChooseStartingDate = parameters?.items?.enableChooseStartingDate

  useEffect(() => {
    if (parameters?.items) {
      const minimumStartingDays =
        typeof parameters?.items?.minimumStartingDays !== 'undefined'
          ? parameters?.items?.minimumStartingDays
          : 7
      const minimumLeasingDays =
        typeof parameters?.items?.minimumLeasingDays !== 'undefined'
          ? parseInt(`${parameters?.items?.minimumLeasingDays}`)
          : 2
      if (
        !cart.item &&
        cart.status !== 'cart deleted' &&
        cart.status !== 'error'
      ) {
        // no state cart
        if (localStorage.getItem('CartToken')) {
          getCart()
        } else {
          // default date
          if (cart.status !== 'cart deleted for DP') {
            if (!isSubscription || isEnableChooseStartingDate) {
              const startDate = dayjs().add(minimumStartingDays, 'day').toDate()
              const endDate = dayjs()
                .add(minimumStartingDays + minimumLeasingDays - 1, 'day')
                .toDate()
              // createCart in BDD and redux state
              createCart(dayjs(startDate), dayjs(endDate), warehouseId)
            } else {
              const startSubscriptionDate = dayjs().add(
                minimumStartingDays,
                'day'
              )
              createSubscriptionCart(
                dayjs(startSubscriptionDate),
                1,
                warehouseId
              )
            }
          }
        }
      } else if (
        (cart.status === 'error' &&
          cart.error?.message === 'Request failed with status code 404') ||
        cart.status === 'cart deleted'
      ) {
        // cart not found, create new one
        // remove current Cart token to save the new one
        localStorage.removeItem('CartToken')

        if (!isSubscription) {
          const savedStartDate = localStorage.getItem(`${SAVED_START_DATE}`)
          const endStartDate = localStorage.getItem(`${SAVED_END_DATE}`)
          const startDate = dayjs().add(minimumStartingDays, 'day').toDate()
          const endDate = dayjs()
            .add(minimumStartingDays + minimumLeasingDays, 'day')
            .toDate()
          let useSavedDate = false
          // createCart in BDD and redux state
          if (
            dayjs(savedStartDate, 'DD/MM/YYYY').diff(dayjs(startDate), 'day') >=
            0
          ) {
            //saved start date is valid
            useSavedDate = true
          }
          createCart(
            savedStartDate && useSavedDate
              ? dayjs(savedStartDate, 'DD/MM/YYYY')
              : dayjs(startDate),
            endStartDate && useSavedDate
              ? dayjs(endStartDate, 'DD/MM/YYYY')
              : dayjs(endDate),
            warehouseId
          )
        } else {
          const savedStartDate = localStorage.getItem(`${SAVED_START_DATE}`)
          const startDate = dayjs().add(minimumStartingDays, 'day')
          let useSavedDate = false
          if (
            dayjs(savedStartDate, 'DD/MM/YYYY').diff(dayjs(startDate), 'day') >=
            0
          ) {
            //saved start date is valid
            useSavedDate = true
          }
          createSubscriptionCart(
            savedStartDate && useSavedDate
              ? dayjs(savedStartDate, 'DD/MM/YYYY')
              : dayjs(startDate),
            1,
            warehouseId
          )
        }
      }
    }
    if (
      cart?.item &&
      cart.status === 'cart live' &&
      !localStorage.getItem('CartToken')
    ) {
      localStorage.setItem('CartToken', cart.item.tokenValue)
    }
  }, [cart.item, cart.status, parameters?.items])

  // Delete cart if locale change
  useEffect(() => {
    if (cart?.item) {
      if (cart?.item?.locale.substring(0, 2) !== router.locale) {
        deleteCart()
      }
    }
  }, [router.locale])

  // Update a subscription cart to receive informations about price changes because of current subscription
  useEffect(() => {
    // We need to be in subscription mode, we need a cart and a user, and a specific cart state (shipping method not currently selected)
    if (
      isSubscription &&
      cart?.item?.tokenValue &&
      user?.user?.id &&
      cart?.item?.checkoutState === 'cart' &&
      !cart?.isUpdated
    ) {
      updateCartSubscription()
    }
  }, [cart?.item?.tokenValue, user?.user?.id])

  // Products Stock Management
  useEffect(() => {
    if (didMountProducts.current === true) {
      if (
        products?.items?.items?.length > 0 &&
        products.loading === false &&
        cart.status === 'cart live'
      ) {
        // if we use cumulative products, we don't need to find the stock for the products that has already loaded
        // so we cut the current product stock we have frome the array of SKUs to get only the one we charge
        let SKUArray = []
        if (products?.isCumulative) {
          let newArray = []
          newArray = [...products?.items?.items]
          const limit = products.items.limit * (products.items.page - 1)
          if (products.items.page > 1) {
            newArray.splice(0, limit)
          }
          SKUArray = getSkus(newArray)
        } else {
          SKUArray = getSkus(products)
        }

        if (
          cart.item?.startLeasingDate &&
          cart.item?.endLeasingDate &&
          !isSubscription
        ) {
          if (SKUArray.length > 0) {
            const data = {
              lizeeSKUs: SKUArray,
              from: dayjs(cart.item?.startLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              to: dayjs(cart.item?.endLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
            }
            getStock(data, warehouseId)
          }
        } else {
          const SKUArray = getSkus(products)
          if (SKUArray.length > 0) {
            const data = {
              lizeeSKUs: SKUArray,
            }
            getStock(data, warehouseId)
          }
        }
      } else {
        getStock([], warehouseId)
      }
    } else didMountProducts.current = true
  }, [products.items, cart.item?.startLeasingDate, cart.item?.endLeasingDate])

  // Basket Stock Management
  useEffect(() => {
    if (cart?.item && cart.item.items?.length > 0) {
      const SKUArray = []
      cart?.item?.items?.map((item) => {
        SKUArray.push(item.product.variants[0].sku)
      })
      if (
        cart.item?.startLeasingDate &&
        cart.item?.endLeasingDate &&
        !isSubscription
      ) {
        if (SKUArray.length > 0) {
          const data = {
            lizeeSKUs: SKUArray,
            from: dayjs(cart.item?.startLeasingDate, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            ),
            to: dayjs(cart.item?.endLeasingDate, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            ),
          }
          getBasketStock(data, warehouseId)
        }
      } else {
        if (SKUArray.length > 0) {
          const data = {
            lizeeSKUs: SKUArray,
          }
          getBasketStock(data, warehouseId)
        }
      }
    }
  }, [
    cart?.item?.items,
    cart.item?.startLeasingDate,
    cart.item?.endLeasingDate,
  ])

  // Single product Stock Management
  useEffect(() => {
    if (didMountProduct.current === true) {
      if (
        product.item &&
        product.loading === false &&
        cart.status === 'cart live'
      ) {
        if (
          cart.item?.startLeasingDate &&
          cart.item?.endLeasingDate &&
          !isSubscription
        ) {
          const SKUArray = getSkus(product.item)
          if (SKUArray.length > 0) {
            const data = {
              lizeeSKUs: SKUArray,
              from: dayjs(cart.item?.startLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              to: dayjs(cart.item?.endLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
            }
            getStock(data, warehouseId)
          }
        } else {
          const SKUArray = getSkus(product.item)
          if (SKUArray.length > 0) {
            const data = {
              lizeeSKUs: SKUArray,
            }
            getStock(data, warehouseId)
          }
        }
      }
    } else didMountProduct.current = true
  }, [product.item, cart.item?.startLeasingDate, cart.item?.endLeasingDate])

  // Pack Stock Management
  useEffect(() => {
    if (didMountPacks.current === true) {
      if (
        packs?.items?.length > 0 &&
        packs.loading === false &&
        cart.status === 'cart live'
      ) {
        if (
          cart.item?.startLeasingDate &&
          cart.item?.endLeasingDate &&
          !isSubscription
        ) {
          let data = {}
          let SKUArray = []
          packs?.items?.map((pack) => {
            const SKUs = getSkus(pack.packProducts)
            const listAssociatedItems = getAssociatedProducts(
              pack?.productsAssociations
            )
            const ProductsSKUs = getSkus(listAssociatedItems)
            if (SKUArray.length > 0) {
              if (ProductsSKUs.length > 0) {
                const SKUsArray = []
                SKUsArray.push(...SKUs, ...ProductsSKUs)

                SKUArray = arrayUnique(SKUArray.concat(SKUsArray))
              } else {
                SKUArray = arrayUnique(SKUArray.concat(SKUs))
              }
            } else {
              if (ProductsSKUs.length > 0) {
                const SKUsArray = []
                SKUsArray.push(...SKUs, ...ProductsSKUs)
                SKUArray = SKUsArray
              } else {
                SKUArray = SKUs
              }
            }
          })
          if (SKUArray.length > 0) {
            data = {
              lizeeSKUs: SKUArray,
              from: dayjs(cart.item?.startLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              to: dayjs(cart.item?.endLeasingDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
            }
            getStock(data, warehouseId)
          }
        } else {
          let data = {}
          let SKUArray = []
          packs.items?.map((pack) => {
            const SKUs = getSkus(pack.packProducts)
            const listAssociatedItems = getAssociatedProducts(
              pack?.productsAssociations
            )
            const ProductsSKUs = getSkus(listAssociatedItems)
            if (SKUArray.length > 0) {
              if (ProductsSKUs.length > 0) {
                const SKUsArray = []
                SKUsArray.push(...SKUs, ...ProductsSKUs)

                SKUArray = arrayUnique(SKUArray.concat(SKUsArray))
              } else {
                SKUArray = arrayUnique(SKUArray.concat(SKUs))
              }
            } else {
              if (ProductsSKUs.length > 0) {
                const SKUsArray = []
                SKUsArray.push(...SKUs, ...ProductsSKUs)
                SKUArray = SKUsArray
              } else {
                SKUArray = SKUs
              }
            }
          })
          if (SKUArray.length > 0) {
            data = {
              lizeeSKUs: SKUArray,
            }
            getStock(data, warehouseId)
          }
        }
      }
    } else didMountPacks.current = true
  }, [packs.items, cart.item?.startLeasingDate, cart.item?.endLeasingDate])

  useEffect(() => {
    if (user?.user?.id) {
      // getUserOrders if user exists and has no order saved in state
      if (!user?.orders) {
        getUserOrders()
        if (process.env.STORE_TYPE === 'subscription') {
          const asyncFunc = async () => {
            const contract = await getInProgressContractSUB('in_progress')
            if (
              contract.type === 'GET_IN_PROGRESS_CONTRACT_SUB_FAILURE' ||
              contract.currentContract.length === 0
            ) {
              await getInProgressContractSUB('in_coming')
            }
          }
          asyncFunc()
        }
      }
      // getUserBalance if user exists and has no balance saved in state
      if (!user?.balance) {
        getUserBalance()
      }
    }
  }, [user?.user?.id])

  // handle user automatic connection
  useEffect(() => {
    // We check if there is an existing bearer token but no user state
    if (
      typeof localStorage.getItem('TOKEN') !== 'undefined' &&
      localStorage.getItem('TOKEN') !== null &&
      !user?.user?.id
    ) {
      // if it is the case (ie client reload or come back on the site after he was connected before)
      const anyNameFunction = async () => {
        // we try to get user based on current token.
        const user = await getUser()
        // if we receive an error (ie expired token)
        if (user.type === 'GET_USER_FAILURE') {
          // We remove the token from localStorage and we delete everything registered in current redux state
          localStorage.removeItem('TOKEN')
          localStorage.removeItem('connexion-type')
          deleteUser()
        }
      }
      anyNameFunction()
    }
  }, [])

  // get promotions
  useEffect(() => {
    if (typeof promotions?.items === 'undefined') {
      getPromotions()
    }
  }, [promotions?.items])

  //Futur classic subscription price if connected
  useEffect(() => {
    /*
      Here we watch 3 states : the items in current cart, the promotions
    */
    /* Here we have to check if the user exists, that he already have some orders, 
    that we are in classic_subscription, if we have some promotions and if we have products in our customer's cart */
    if (
      process.env.STORE_TYPE === 'classic_subscription' &&
      promotions?.items?.length > 0 &&
      cart?.item?.items?.length > 0
    ) {
      const asyncFunction = async () => {
        let contract = await getInProgressContractCSUB('in_progress')
        if (
          contract.type === 'GET_IN_PROGRESS_CONTRACT_SUB_FAILURE' ||
          contract?.currentContract?.length === 0
        ) {
          contract = await getInProgressContractCSUB('in_coming')
        }
        let numberInCurrent = 0
        // We go through the user order array to calcul how many products we have
        // if user connected, we check current sub
        if (contract?.currentContract?.length > 0) {
          contract.currentContract[0].orders.map((order) => {
            if (
              order.paymentState !== 'paid' &&
              order.paymentState !== 'cancelled' &&
              order.paymentState !== 'refunded'
            ) {
              order.items?.map((item) => {
                let qteReturned = 0
                item.units?.map((unit) => {
                  if (unit.isReturned) {
                    qteReturned += 1
                  }
                })
                numberInCurrent += item.quantity - qteReturned
              })
            }
          })
        } else if (cart?.item?.items?.length > 0) {
          cart?.item?.items?.map((product) => {
            numberInCurrent += product.quantity
          })
        }
        const futurPrice = 0
        // We add 1 if a client has an order and returned all the products, he currently has 0 product but futurPrice cannot be null. Therefore we setup it at one to match the first promotion.
        if (numberInCurrent === 0) {
          numberInCurrent = 1
        }
        // We check how many products we have in the current cart
        // We go through each promotions, to find wich one will be applied to the user if he choose to continue with his current subscription and the cart he is currently creating
        // Filter plans of coupon based promotions
        const futurPlan = findCurrentPlan(
          promotions,
          numberInCurrent,
          futurPrice
        )
        // set the variable in a redux state, to have it everywhere it is needed
        setFuturePrice(futurPlan)
      }

      asyncFunction()
    }
  }, [
    cart?.item?.items,
    promotions?.items,
    user?.user?.id,
    user?.orders?.length,
  ])

  // Function to destroy any cart created before today's date
  useEffect(() => {
    if (cart?.item?.startLeasingDate && parameters?.items) {
      const asyncFunc = async () => {
        const minimumStartingDays =
          typeof parameters?.items?.minimumStartingDays !== 'undefined'
            ? parameters?.items?.minimumStartingDays
            : 7
        const minimumLeasingDays =
          typeof parameters?.items?.minimumLeasingDays !== 'undefined'
            ? parseInt(`${parameters?.items?.minimumLeasingDays}`)
            : 2
        const startDate = dayjs().add(minimumStartingDays, 'day').toDate()
        const endDate = dayjs()
          .add(minimumStartingDays + minimumLeasingDays - 1, 'day')
          .toDate()

        if (
          dayjs(cart?.item.startLeasingDate, 'DD/MM/YYYY').diff(
            startDate,
            'day'
          ) < 0
        ) {
          await deleteCart()
          if (!isSubscription) {
            await createCart(dayjs(startDate), dayjs(endDate), warehouseId)
          } else {
            await createSubscriptionCart(dayjs(startDate), 1, warehouseId)
          }
        }
      }
      asyncFunc()
    }
  }, [cart?.item?.startLeasingDate, parameters?.items])

  // Save chosen date
  useEffect(() => {
    if (cart?.item?.startLeasingDate && cart?.item?.endLeasingDate) {
      localStorage.setItem(`${SAVED_START_DATE}`, cart?.item?.startLeasingDate)
      localStorage.setItem(`${SAVED_END_DATE}`, cart?.item?.endLeasingDate)
    }
  }, [cart?.item?.startLeasingDate, cart?.item?.endLeasingDate])

  // Get parameters
  useEffect(() => {
    if (!parameters?.items) {
      getParameters()
    }
  }, [])

  // Get available shipping countries
  useEffect(() => {
    if (!parameters?.countries) {
      getCountries()
    }
  }, [])

  return <>{children}</>
}

export default connect(mapStateToProps, mapDispatchToProps)(CartManager)
